import { Loader2 } from 'lucide-react';
import React, { PureComponent, useMemo } from 'react';
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Cell } from 'recharts';

interface PiechartProps {
    piechartData: any[],
    loading: boolean
    colors: string[]
    legentname?: string
}
const PieCharts = ({ piechartData, loading, colors, legentname }: PiechartProps) => {

    const data = useMemo(() => piechartData ? piechartData : [], [piechartData])
    const sum = useMemo(() => data ? data.reduce((acc, cur) => { return acc + cur.value }, 0) : 0, [data])


    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };


    const getIntroOfPage = (label: any) => {
        if (label === 'workinprogressyescount') {
            return " In progress ";
        }
        if (label === 'workinprogressnocount') {
            return " Started and Stopped";
        }
        if (label === 'worknotstartcount') {
            return "Not yet started";
        }
        if (label === 'physicalprogresscount') {
            return 'Physical Progress';
        }
        if (label === 'financialprogresscount') {
            return 'Financial Progress';
        }
        return '';
    };

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="text-xs p-2 border rounded-full font-semibold text-gray-800 bg-gray-300">{getIntroOfPage(payload[0].name)} : {payload[0].value}</p>

                </div>
            );
        }

        return null;
    };

    // const CustomLegend = () => (
    //     <ul className="w-full flex flex-col md:flex-row space-y-1 items-center  custom-legend space-x-5  justify-center py-2 whitespace-nowrap">
    //         {/* <h2 className='font-semibold text-base text-gray-800 mb-2'>{legentname}</h2> */}
    //         {data.map((entry, index) => (
    //             <li key={`legend-${index}`} className='w-full text-left  flex justify-center items-center gap-x-2 ml-4'>
    //                 <span style={{
    //                     backgroundColor: `${colors[index]} `,
    //                 }} className='w-4 h-4  rounded-sm text-left'></span>
    //                 <span className='w-full font-normal text-xs whitespace-nowrap  text-legent text-left '>{getIntroOfPage(entry.name)}</span>
    //             </li>
    //         ))}
    //     </ul>
    // );

    const loading_data = [
        { name: 'Loading', value: 1 },
    ];

    const no_data = [
        { name: 'No Data', value: 1 }
    ]

    return (
        <div className='w-full  ' >
            {loading ? (
                <div className='w-full flex justify-center items-center'>

                    <div className='flex justify-center items-center '>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span></div>

                    <PieChart width={230} height={230} >
                        <Pie
                            data={loading_data}
                            fill="#979797"
                            dataKey="value"

                        />
                        <Legend />
                    </PieChart>


                </div>) : sum === 0 ? (
                    <div className='w-full flex justify-center items-center'>
                        <PieChart width={230} height={230} >
                            <Pie
                                data={no_data}
                                fill="#808080"
                                dataKey="value"
                                outerRadius={100}
                            />
                            <Legend />
                        </PieChart>
                    </div>) : (

                // <ResponsiveContainer >
                <div className='w-full flex justify-center items-center'>
                    <PieChart width={300} height={300}
                    >

                        <Pie
                            data={data}
                            // cx="70%"
                            // cy="35%"
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={110}
                            fill="#8884d8"
                            dataKey="value"

                        >   {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                        ))}</Pie>

                        <Tooltip content={CustomTooltip} />

                        {/* <Legend layout="horizontal" verticalAlign="bottom" content={CustomLegend} /> */}


                    </PieChart>
                </div>
                // </ResponsiveContainer>
            )}


        </div >


    )
}

export default PieCharts