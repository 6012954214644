import Headers from './Header/Headers'
import SideBarComp from './Sidebar/SideBarComp'
import React from 'react'
import { Outlet } from 'react-router-dom'

const AppLayout = () => {
    return (
        <div className='w-full h-screen relative'>
            <Headers />
            <div className='w-full  h-full '>
                <div className='w-full flex h-full'>
                    <SideBarComp />



                    <Outlet />
                </div>
                {/* <div className='bottom-0  w-full flex justify-end items-center  test-xs text-gray-700'>
                    © 2020 Orange Current Technologies Pvt. Ltd. All rights reserved
                </div> */}

            </div>

        </div>
    )
}

export default AppLayout
