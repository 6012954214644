import { configureStore } from "@reduxjs/toolkit";
import dropdownSlicer from "./Slicer/dropdownSlicer";
import errorHadlingSlicer from "./Slicer/errorHadlingSlicer";
// import piechartSlicer from "./Slicer/piechartSlicer";




export const store=configureStore(
  {  reducer:{
dropdown:dropdownSlicer,
// piechart:piechartSlicer
errorBoundry: errorHadlingSlicer,
    }
}
)

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;