
import { useSelector } from 'react-redux'
import Dashboard from './Components/Dashboard'
import React, { useEffect } from 'react'
import { RootState } from 'src/redux/store'
import { dropDownReducerState } from 'src/redux/Slicer/dropdownSlicer'
import { toast } from 'react-toastify'



type Props = {}

const DashboardMain = (props: Props) => {
  const { error, message } = useSelector((state: RootState) => dropDownReducerState(state))
  useEffect(() => {
    if (error) {
      toast.error(message)
    }
    return () => { }
  }, [error, message])
  return (
    <div className='w-full ml-0 md:ml-[16.66%] mt-[5%] z-10 h-full'>
      <Dashboard />
    </div>
  )
}



export default DashboardMain

