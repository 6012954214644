import axios, { AxiosResponse } from 'axios'
import { VILLAGE_NAME } from 'src/service/apis'

const getVillageDropDown = (query: string) => {
    return axios
        .get(`${VILLAGE_NAME}?${query}`
           

        )
        .then((d: AxiosResponse<any>) => d.data)
}

export { getVillageDropDown }