import React, { InputHTMLAttributes } from 'react'
import { Input } from 'src/components/ui/input'
import { Label } from 'src/components/ui/label'
interface InputsComp extends InputHTMLAttributes<HTMLInputElement> {

  lable?: string
  lableClass?: string,
  errorMessage?: string
  cls?: string
  regex?: any
  onchange?: (e: any) => void
  value?: any;
}
const InputComp: React.FC<InputsComp> = ({ lable, lableClass, errorMessage, cls, regex, onchange, value, ...rest }) => {
  return (
    <div className='w-full my-2'>
      <Label htmlFor={rest.id} className={`text-legent text-sm font-semibold ml-2 ${lableClass}`}>{lable}</Label>
      <Input
        value={value}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (regex && regex) {
            let cleanedValue = e.target.value.replace(/[^0-9]+/g, "");
            onchange && onchange(Number(cleanedValue))
          } else {
            onchange && onchange(e.target.value)
          }
        }}
        {...rest}
        className={`block w-full border border-input border-[#C6C6C6] bg-white   rounded-full py-1.5  pr-20 placeholder:text-gray-400 placeholder:text-xs  ${cls}`} />
      <p className='text-sm text-red-600 ml-2 font-medium'>{errorMessage}</p>
    </div>
  )
}

export default InputComp

