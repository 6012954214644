
import PhysicalProgress from "../ReportCom/PhysicalProgress"
import FinicialProgress from "../../Context/ReportCom/FinicialProgress"
import { ColumnDef } from "@tanstack/react-table"
import { Check, X } from "lucide-react"



export type ReportTable = {
  name: string,
  nameOfVillage: string,
  districtSchema: string,
  divisionSchema: string,
  nameOfFirm: string,
  physicalPrgress: string,
  financialProgress: string,
  // presentStatus: string,
  schemaName: string,
  nitNumber: string
}



export const reportColumn: ColumnDef<ReportTable>[] = [
  {
    accessorKey: "divisionSchema",
    header: "Division",
  },
  {
    accessorKey: "nitNumber",
    header: "NIT Number",

  },
  {
    accessorKey: "schemaName",
    header: "Scheme Name",
  },
  {
    accessorKey: "nameOfVillage",
    header: "Name of village",
  },
  {
    accessorKey: "name",
    header: "Name",
    cell: ({ row }) => {
      let val: string = row.getValue('name')
      return val
    }
  },

  // {
  //   accessorKey: "districtSchema",
  //   header: "Name of district",
  // },


  {
    accessorKey: "physicalPrgress",
    header: "Physical progress",
    cell: ({ row: { original } }) => {
      return <PhysicalProgress {...original}
      />
    }
    // cell: ({ row }) => {
    //   let val: string = row.getValue('physicalPrgress')
    //   return val !== '' && val !== 'null' ? Number(val)?.toFixed(2) : "-"
    // }
  },
  {
    accessorKey: "financialProgress",
    header: "Financial progress",
    cell: ({ row: { original } }) => {
      return <FinicialProgress {...original}
      />
    }
    // cell: ({ row }) => {
    //   let val: string = row.getValue('financialProgress')
    //   // console.log(val)
    //   if (val === null) {
    //     return '_';
    //   } else if (val.includes('%')) {
    //     return val.split('%')[0];
    //   } else {
    //     return val;
    //   }

    // }
  },

  // {
  //   accessorKey: "presentStatus",
  //   header: "Status",
  //   cell: ({ row }) => {
  //     let val: string = row.getValue('presentStatus')
  //     return val !== '' && val !== 'null' ? val : "-"
  //   }
  // },
]

