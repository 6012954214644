import axios, { AxiosResponse } from 'axios'
import { AVABILITY_OF_CONTRACTOR } from 'src/service/apis'

const getAvaibilityOfContractor = (query: string) => {
    return axios
        .get(`${AVABILITY_OF_CONTRACTOR}?${query}`
           

        )
        .then((d: AxiosResponse<any>) => d.data)
}

export { getAvaibilityOfContractor }