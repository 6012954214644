import { Input } from 'src/components/ui/input'
import React, { useRef, useState } from 'react'
import { Eye, FolderUp, Loader2 } from 'lucide-react'
import { useFormik } from 'formik';

import { Button } from 'src/components/ui/button';
import DivisionForFileUpload from 'src/components/ui/Common/Filter/Division/DivisionForFileUpload';
import InputComp from 'src/components/ui/Common/InputsComponents/InputComp';

import LetterIssueFrom from 'src/components/ui/Common/Filter/LetterDropDown/LetterIssueFrom';
import { postUploadFileApi } from 'src/service/services';
import { ENUM_STATUS_CODE } from 'src/helpers/index';
import axios, { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateError, updateErrorCode, updateSuccessMess } from 'src/redux/Slicer/errorHadlingSlicer';



import CalenderFilter from 'src/components/ui/Common/Datepicker/CalenderFilter';
import { BillProps,BillInitialValue,BillValidationSchema } from './billModule';



const Bill = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [dragging, setDragging] = useState(false);

    // const [open,setOpen]=useState<boolean>(false)
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch()

   
    const handleIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const formik = useFormik<BillProps>({
        initialValues: BillInitialValue,
        onSubmit: async (values, { resetForm, setValues }) => {


            let formData = new FormData();
            formData.append('NitNumber', values.NitNumber||'');
            formData.append('billNo', values.billNo||'');
            formData.append('contractorName', values.contractorName || '');
            formData.append('presentBillAmt', values.presentBillAmt || '');
            formData.append('previousBillAmt', values.previousBillAmt || '');
            formData.append('division', values.division);
            formData.append('type', 'BILL');
            formData.append('dt', values?.dt);
            if (values.pdf1) {
                formData.append('pdf1', values.pdf1);
            }

            handleSendOtpApi(formData)
        },

        validationSchema: BillValidationSchema
    })

// drag and drop feature

const handleDragOver = (event:any) => {
    event.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (event:any) => {
    event.preventDefault();
    setDragging(false);
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
    
      formik.setFieldValue('pdf1', files[0])
    }
  };


//   Api function calling
    const handleSendOtpApi = async (postData: any) => {
        console.log('object')
        setLoading(true)
        try {
            let apiResp = await postUploadFileApi(postData);
            dispatch(updateErrorCode(apiResp.code))
            if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {


                formik.resetForm();
                dispatch(updateSuccessMess(apiResp?.msg))

            } else {

                dispatch(updateError(apiResp.msg))
                dispatch(updateErrorCode(apiResp.code))
                // toast.error(apiResp.message)
            }
            return apiResp.data
        } catch (err) {
            let error = err as Error | AxiosError
            if (axios.isAxiosError(error)) {
                dispatch(updateError(error.response?.data.msg))
                dispatch(updateErrorCode(error.response?.data.code))
                // toast.error(error.response?.data.message)
            }
            else {

                dispatch(updateError(error.message))
                // toast.error(error.message)

            }
        } finally {
            setLoading(false); // Set loading state to false when request completes (whether success or failure)
        }
    }

    return (
        <div className='w-full'>
            <form onSubmit={formik.handleSubmit}>


                <div className='w-full md:flex md:justify-between md:space-x-16'>
                    <div className='md:w-2/5 w-full'
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                    >
                        <div className='border-[3px] border-blue border-dashed h-52 w-full flex flex-col justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <FolderUp className='text-dashboardText  w-12 h-12 cursor-pointer' onClick={handleIconClick} />
                            </div>

                            <Input
                                type='file'
                                className='border border-input border-none hidden'
                                ref={fileInputRef}
                                // onChange={(e: any) => formik.setFieldValue('pdf1', e.target?.files[0])}
                                accept='.pdf'
                                onChange={(e: any) => {
                                    const file = e.target?.files[0];

                                    if (file && file.size < 15728640) {

                                        formik.setFieldValue('pdf1', file);

                                    } else {

                                        dispatch(updateError('File size less than 15MB.'))
                                    }
                                }}
                            // value={formik.values?.pdf1}
                            />


                            <div className='text-[#737373] text-xs font-normal text-center'>Drag and drop your file</div>
                            <div className='text-dashboardText text-xs font-normal text-center'>{formik?.values?.pdf1?.name}</div>
                        </div>
                        <p className='text-sm text-red-600 ml-2 font-medium'>{formik.touched?.pdf1 && formik.errors?.pdf1 ? formik.errors?.pdf1 : ''}</p>
                        <div className='text-[#737373] text-xs font-normal w-full my-2 text-center'>
                            File should be in PDF format
                        </div>

                     
                    </div>


                    <div className='w-full -mt-4'>


                        <div className='w-full md:grid md:grid-cols-2 md:gap-x-3 '>

                            <div>
                                <CalenderFilter dts={formik?.values?.dt} errorMessage={formik.touched?.dt && formik.errors?.dt ? formik.errors?.dt : ''}
                                    onchange={(val: string) => formik?.setFieldValue('date', val)}
                                />
                            </div>

                            <DivisionForFileUpload
                                dropDownChange={(val) => formik.setFieldValue('division', val)}
                                erroMessage={formik.touched?.division && formik.errors?.division ? formik.errors?.division : ''}
                                values={formik.values?.division} />

                            <InputComp
                                errorMessage={formik.touched?.contractorName && formik.errors?.contractorName ? formik.errors?.contractorName : ''}
                                // regex={new RegExp('/[^0-9]+/g')}
                                value={formik?.values?.contractorName}
                                onchange={(val: any) => formik.setFieldValue('contractorName', val)}
                                type='text'
                                id='contractorName'
                                lable='Contractor name'
                                placeholder='Enter Contractor Name'
                                // maxLength={12}
                            />
                            <InputComp
                                errorMessage={formik.touched?.NitNumber && formik.errors?.NitNumber ? formik.errors?.NitNumber : ''}
                                regex={new RegExp('/[^0-9]+/g')}
                                value={formik?.values?.NitNumber}
                                onchange={(val: any) => formik.setFieldValue('NitNumber', val)}
                                type='text'
                                id='NitNumber'
                                lable='NIT number'
                                placeholder='Enter NIT number'
                                  maxLength={12}
                            />
                         
               
                            <InputComp
                                errorMessage={formik.touched?.presentBillAmt && formik.errors?.presentBillAmt ? formik.errors?.presentBillAmt : ''}
                                regex={new RegExp('/[^0-9]+/g')}
                                value={formik?.values?.presentBillAmt}
                                onchange={(val: any) => formik.setFieldValue('presentBillAmt', val)}
                                type='text'
                                id='presentBillAmt'
                                lable='Work order amount'
                                placeholder='Work order amount'
                            />

<InputComp
                                errorMessage={formik.touched?.previousBillAmt && formik.errors?.previousBillAmt ? formik.errors?.previousBillAmt : ''}
                                regex={new RegExp('/[^0-9]+/g')}
                                value={formik?.values?.previousBillAmt}
                                onchange={(val: any) => formik.setFieldValue('previousBillAmt', val)}
                                type='text'
                                id='previousBillAmt'
                                lable='Up to previous bill'
                                placeholder='Up to previous bill'
                            />
                            <InputComp
                                errorMessage={formik.touched?.billNo && formik.errors?.billNo ? formik.errors?.billNo : ''}
                                regex={new RegExp('/[^0-9]+/g')}
                                value={formik?.values?.billNo}
                                onchange={(val: any) => formik.setFieldValue('billNo', val)}
                                type='text'
                                id='bill number'
                                lable='Bill Number'
                                placeholder='Enter Bill Number'
                            />

                        </div>

                    </div>
                </div>
                <div className='w-full flex justify-center items-center mt-16'>
                    <Button className='lg:w-1/5 w-full bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold'
                        type='submit' disabled={loading}>
                        {loading ? (<> <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Please Wait...</span></>) : "Submit"}
                    </Button>
                </div>
            </form>
            {/* <Modale open={open} children={<LetterModulePdf file={formik?.values?.pdf1}/>} setOpen={setOpen}/>
            <LetterModulePdf file={formik?.values?.pdf1}/> */}
        </div>
    )
}

export default Bill
