import { useQueryResponse } from './Context/Core/QueryResponseProvider'
import DistrictFilter from '../DropDown'
import { QueryRequestProvider, QueryResponseProvider, DistrictDropDownMainState } from './Context'

import React, { useEffect, useMemo, useState } from 'react'
import { useQueryResponseLoading } from './Context/index'
import DropDown from '../DropDown'
import { stat } from 'fs'
import { useDispatch, useSelector } from 'react-redux'
import { districtDropdownState, dropDownReducerState } from 'src/redux/Slicer/dropdownSlicer'
import { RootState } from 'src/redux/store'



type Props = {}

const Main = (props: Props) => {
    const [selecteVal, setSelectVal] = useState<string>('')
    const { calendeChange, division } = useSelector((state: RootState) => dropDownReducerState(state))
    const { response } = useQueryResponse();
    const isLoading = useQueryResponseLoading();
    const { state, updateState } = DistrictDropDownMainState();
    const apiResponse: any = useMemo(() => response, [response]);
    const tableData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? (apiResponse?.data ? apiResponse?.data.filter((cur: string) => cur !== '') : []) : []),
        [apiResponse, isLoading]
    );
    const dispatch = useDispatch()
    const handleState = (val: string) => {


        setSelectVal(val)
        dispatch(districtDropdownState(val))
    }

    useEffect(() => {
        updateState({
            filter: {
                division: division
            }
        })
    }, [division]);


    return (
        <div className='w-full'>
            <DropDown
                id='districtFilter'
                name='Select Scheme'
                lable='Scheme Name'
                jsonData={tableData || []}
                onChange={handleState}
                value={selecteVal || ''}
                isLoading={isLoading} />
        </div>
    )
}

const DistrictMain: React.FC<Props> = ({ }) => {

    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default DistrictMain
