import { Label } from 'src/components/ui/label'
import React from 'react'
import { RadioGroup, RadioGroupItem } from 'src/components/ui/radio-group'
import { ENUM_PURCHASE_TYPE } from 'src/helpers'
interface FilterMainProps{
  setFilterState:(val:any)=>void
}
const FilterMain = ({setFilterState}:FilterMainProps) => {

    const filterState=[{
        label:'Letter',
        value:'letter',
    },
    {
        label:'Bill',
        value:'bill',
    },
    {
        label:'Factory Inspection',
        value:'factoryInspection',
    },]


  return (
    <div className='w-full'>

        
          <RadioGroup defaultValue={ENUM_PURCHASE_TYPE?.Letter} className='w-full flex justify-between  items-center' onValueChange={(val:string)=>setFilterState(val)}>

            {
                filterState?.map((cur:any,ind:number)=>{
                    return      <div className="w-full flex items-center   space-x-2">
                    <RadioGroupItem value={cur?.value} id={`r${ind}`} />
                    <Label htmlFor={`r${ind}`} className='text-sm font-medium whitespace-nowrap'>{cur?.label}</Label>
                  </div>
                })
            }


    </RadioGroup>
    </div>
  )
}

export default FilterMain
