

// import AchievedJobSummaryMain from './modules/AchieveJobSummary/DashboardMain';
// import AchievedJobSummaryMain from '@modules';
import ErrorBoundryMain from '../src/modules/ErrorBoundry/ErrorBoundryMain';
import Headers from './modules/Layout/Header/Headers';
import SideBarComp from './modules/Layout/Sidebar/SideBarComp';
import React from 'react';
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>

        <Outlet />
        <ToastContainer
          position="top-right"
          autoClose={5000}
        />
          <ErrorBoundryMain />
      </QueryClientProvider>
    </>
  );
}

export default App;
