import React, { useMemo, useState } from 'react';

import DropDown from '../DropDown';
type Props = {
    dropDownChange: (val: any) => void;
    erroMessage?:string
    // value:string
};

const LetterIssueFrom: React.FC<Props> = ({ dropDownChange,erroMessage }) => {
    const [selecteVal, setSelectVal] = useState<string>("");
 
 const tableData=['Team Leader, WAPCOS']

    const handleState = (val: string) => {
        setSelectVal(val);
        dropDownChange&& dropDownChange(val);
    };

    return (
        <div className='w-full'>
            <DropDown
                id='letterIssueFrom'
                name='Select Letter issued from'
                lable='Letter issued From'
                jsonData={tableData|| []}
                onChange={handleState}
                value={selecteVal || ''}
                isLoading={false}
            />
               {
                    erroMessage?<p className='text-sm text-red-600 ml-2 font-medium'>{erroMessage}</p>:<></>
                }
        </div>
    );
};



export default LetterIssueFrom;
