import dayjs from "dayjs";
import * as Yup from "yup";

export interface BillProps {
    billNo: string;
    contractorName: string;
    NitNumber: string | undefined;

    pdf1: File | null;
    division: string;
    dt: string
    presentBillAmt: string | undefined
    previousBillAmt: string | undefined
}




export const BillInitialValue: BillProps = {
    billNo: "",
    contractorName: "",
    NitNumber: "",
    presentBillAmt: "",
    pdf1: null,
    division: '',
    dt: dayjs().format('YYYY-MM-DD') as string,
    previousBillAmt: ''

};

export const BillValidationSchema = Yup.object().shape({
    billNo: Yup.string().required("Field is required"),
    dt: Yup.string().required("Field is required").nullable(),
    contractorName: Yup.string().required("Field is required"),
    previousBillAmt: Yup.string().required("Field is required"),
    presentBillAmt: Yup.string().required("Field is required"),
    NitNumber: Yup.string().required("Field is required"),
    pdf1: Yup.mixed()
        .required('Field is required')
        .test('fileFormat', 'Only PDF files are allowed', value => {
            if (value) {
                const file = value as File;
                const supportedFormats = ['pdf'];
                return supportedFormats.includes(file.name.split('.').pop()?.toLowerCase() ?? '');
            }
            return true;
        })
        .test('fileSize', 'File size must be less than 15MB', value => {
            if (value) {
                const file = value as File;
                // Convert size to bytes if necessary
                const fileSizeInBytes = file.size;
                return fileSizeInBytes <= 15728640; // 1MB in bytes
            }
            return true;
        }),
    division: Yup.string().required("Field is required"),
});

