import { Link, useLocation } from 'react-router-dom';
import { IMenuItem } from './modules';
import React from 'react'
import { cn } from 'src/helpers/index';
import { useDispatch, useSelector } from 'react-redux';
import { dropDownReducerState, updateDashboardState } from 'src/redux/Slicer/dropdownSlicer';
import { RootState } from 'src/redux/store';
interface MenuProps extends IMenuItem {
    onClick?: () => void;
    open: boolean;

}
const SubMenu: React.FC<MenuProps> = ({ name, iconName, path, open }) => {
    const { pathname } = useLocation()
    const iconClassName = cn(
        "text-gray-400",
        { "text-white": (path && pathname.includes(path)) }
    );
    const { dashboardState } = useSelector((state: RootState) => dropDownReducerState(state))
    const dispatch = useDispatch();

    const sideBarClick = () => {
        dispatch(updateDashboardState(!dashboardState))
    }
    return (
        <div className={cn('w-full ', { 'hidden': open === true })}>
            {path && (
                <Link to={path} onClick={sideBarClick} className={`flex my-1 items-center h-8 hover:bg-gray-700 text-sm mx-1 rounded-md cursor-pointer pl-4  ${path && pathname.includes(path) ? 'text-dashboardText font-bold' : 'text-legent font-normal'}`}>
                    <p className={'flex justify-center items-center space-x-3 '}>
                        {iconName(iconClassName)} <span>{name}</span>
                    </p>
                </Link >
            )}

        </div>
    )
}

export default SubMenu
