import DownloadReport from '../DownloadReport/Component/DownloadReport'
import React from 'react'

const DownloadReportMain = () => {
    return (
        <div className='w-full ml-0 md:ml-[16.66%] mt-[5%] z-10 h-full'>
            <DownloadReport />
        </div>
    )
}

export default DownloadReportMain
