import React from 'react'
import { QueryRequestProvider, QueryResponseProvider, } from './Context'

import Reports from './Component/Reports'



type Props = {}

const Main = (props: Props) => {
  return (
    <div className='md:w-10/12 lg:w-full w-full h-full md:h-max  md:ml-[16.66%] mt-[5%] md:mt-[9%] lg:mt-[5%] overscroll-none'>
      <Reports />
    </div>
  )
}

const ReportMain: React.FC<Props> = ({ }) => {
  return <>
    <QueryRequestProvider >
      <QueryResponseProvider >
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  </>
}

export default ReportMain