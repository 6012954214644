import DivisionMain from 'src/components/ui/Common/Filter/Division/DivisionMain'
import { column } from '../Context/ContractorComp/column'
import Tables from '../Context/ContractorComp/Tables'
import React, { useEffect, useMemo } from 'react'
import DateFilter from 'src/components/ui/Common/DateFilter'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { dropDownReducerState } from 'src/redux/Slicer/dropdownSlicer'
import { Button } from 'src/components/ui/button'
import { useQueryResponse } from '../Context/Core/QueryResponseProvider'

import { AvaibilityMainState, useQueryResponseLoading } from '../Context/index'
import { ChevronRight } from 'lucide-react'
import { Label } from 'src/components/ui/label'

const AvaibilityOfContracter = () => {
    const { monthYear, division } = useSelector((state: RootState) => dropDownReducerState(state));
    const { response }: any = useQueryResponse();
    const isLoading = useQueryResponseLoading();
    const apiResponse: any = useMemo(() => response, [response]);

    const tableData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? apiResponse?.data : []),
        [isLoading, apiResponse]
    );

    const { state, updateState } = AvaibilityMainState();

    const handleChange = () => {
        updateState({
            filter: {
                division: division,
                bym: monthYear
            }
        })
    }

    useEffect(() => {
        updateState({
            filter: {

                bym: monthYear
            }
        })
    }, [])
    return (
        <div className='w-full p-2 mt-10 lg:mt-0 h-full'>
            <div className='w-full px-8 py-2 bg-cardBg rounded-[30px]  md:rounded-[30px]  h-full  lg:my-0'>

                <div className='w-full grid grid-cols-1 lg:grid-cols-4 gap-x-4 gap-y-2 lg:gap-y-2  md:grid-cols-2 my-3  '>
                    <DivisionMain />
                    <div className='w-full '>
                        <Label className='w-full text-legent text-sm font-semibold ml-3'>Select Month/Year</Label>
                        <div className='w-full -mt-6'>
                            <DateFilter monthYear={monthYear} loading={isLoading} />
                        </div>

                    </div>

                    <div className='w-full flex justify-end'>
                        <Button onClick={handleChange} className='md:mt-6 w-full bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold'
                            disabled={isLoading}>GO <ChevronRight size={16} /></Button>
                    </div>
                </div>

                <div className='w-full'>
                    <Tables data={tableData || []} columns={column} />
                </div>
            </div>
        </div>
    )
}

export default AvaibilityOfContracter
