import { Progress } from 'src/components/ui/progress'
import React, { useEffect, useState } from 'react'
interface ProgressBarProps {
    val: string
}
const ProgressBar = ({ val }: ProgressBarProps) => {
    const [progress, setProgress] = useState(0)
    const [indicator, setIndicator] = useState('#68BE61')
    useEffect(() => {
        const timer = setTimeout(() => setProgress(Number(val)), 500)
        return () => clearTimeout(timer)
    }, [])

    useEffect(() => {
        const numericVal = Number(val);
        if (numericVal <= 20) {
            setIndicator('#DA1E28');
        } else if (numericVal > 20 && numericVal <= 50) {
            setIndicator('#F1C21B');
        } else if (numericVal > 50) {
            setIndicator('#50AB2F');
        }
    }, [val]);

    return (
        <div className='w-full'>
            <Progress value={progress} className="w-full" indicatorColor={indicator} />
        </div>
    )
}

export default ProgressBar
