import { Smartphone } from 'lucide-react';
import React, { InputHTMLAttributes } from 'react';

import { Input } from 'src/components/ui/input';
import { Label } from 'src/components/ui/label';
import { cn } from 'src/helpers';
import { Textarea } from '../../textarea';

interface InputsComp extends InputHTMLAttributes<HTMLInputElement> {
    label?: string;
    labelClass?: string;
    errorMessage?: string;
    id: string;
    placeholder?: string

    onchange: (e: any) => void
    value: any;
}

const TextAreaComponent: React.FC<InputsComp> = ({ label, labelClass, errorMessage, onchange, value, id, placeholder, ...rest }) => {


    return (
        <div className='w-full'>
            <div className='w-full '>
                <div className="w-full relative mt-2 shadow-sm">
                    <Label htmlFor={id} className={`text-grayLable text-sm font-semibold ml-2 ${labelClass}`}>{label}</Label>
                    <Textarea
                        className='h-24 w-full border border-input border-[#C6C6C6] bg-white '
                        id={id}
                        placeholder={placeholder}
                        value={value}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onchange && onchange(e.target.value)}

                    />
                </div>
                {
                    errorMessage && <p className='text-sm text-red-600 ml-2 font-medium'>{errorMessage}</p>
                }

            </div>
        </div>
    );
};

export default TextAreaComponent;
