import { Card } from 'src/components/ui/card'
import React from 'react'
import { Loader2 } from 'lucide-react'

interface BlockProps {
    value: number
    heading: string
    looding: boolean,
    imge: string
}


const Block = ({ heading, value, looding, imge }: BlockProps) => {
    // console.log(value)
    return (
        <Card className='w-full  p-4  '>
            {looding ? <div className='flex justify-center items-center text-sm text-gray-500'>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span >Loading...</span>
            </div> : ""}
            <div className='w-full flex items-center justify-center space-x-4'>
                <div className='w-1/4 rounded-full bg-imgFill p-2 flex justify-center items-center'>
                    <img src={imge} alt='img' className='' />
                </div>
                <div className='w-full'>
                    <h2 className='w-full text-legent font-normal text-xs'>{heading}</h2>
                    <h1 className='text-legent font-bold text-2xl '>{value} L</h1>
                </div>
            </div>

        </Card>
    )
}

export default Block
