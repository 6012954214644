import LetterMain from 'src/modules/UploadReport/Letter/LetterMain'
import FilterMain from './Filter/FilterMain'
import React, { useState } from 'react'
import { ENUM_PURCHASE_TYPE, ENUM_STATUS_CODE } from 'src/helpers/index'
import BillMain from './Bill/BillMain'
import FileMain from './File/FactoryMain'

const UploadReportMain = () => {
    const [filterState, setFilterState] = useState(ENUM_PURCHASE_TYPE?.Letter)
    console.log(filterState)
    return (
        <div className='w-full ml-0 md:ml-[16.66%] mt-[5%] z-10 h-full'>
            <div className='w-full p-2 mt-10 md:mt-6 lg:mt-0 h-full '>
                <div className='w-full px-8 py-8 bg-cardBg  rounded-[30px] h-max md:h-full  md:rounded-[30px]   lg:my-0 border border-white'>
                    <div className='text-dashboardText test-sm font-medium mb-2'>
                        Upload Your Files
                    </div>

                    <div className=' w-full md:w-1/2 mb-6'>
                        <FilterMain setFilterState={setFilterState} />
                    </div>
                    <div className='w-full h-full'>
                        {
                            filterState === ENUM_PURCHASE_TYPE?.Letter ? (<LetterMain />) : filterState === ENUM_PURCHASE_TYPE?.Bill ? (<BillMain />) : (<FileMain />)
                        }

                    </div>
                </div>

            </div>

        </div>
    )
}

export default UploadReportMain
