import React, { useState } from 'react'
import { Button } from 'src/components/ui/button'
import { Download } from 'lucide-react'
import ReportDropDown from '../Core/ReportDropDown'
import DivisionMain_6 from 'src/components/ui/Common/Filter/Division_1/DivisionMain_6'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { dropDownReducerState } from 'src/redux/Slicer/dropdownSlicer'
import { reportDownload } from 'src/service/services'
import { BILL_PDF_GENRATE, HINDERANCE_REPORT, LETTER_PDF_GENRATE, REVISITE_REPORT } from 'src/service/apis'
const AllReport = () => {
    const [report, setReport] = useState('Hindrance Report')
    const handleState = (val: string) => {
        setReport(val)
    }

    const { monthYear, reportDivision, calendeChange, downloadReportDivision, division, villageState } = useSelector((state: RootState) => dropDownReducerState(state))

    const handleOtherReport = () => {
        if (report === 'Hindrance Report' && reportDivision !== '') {
            let query = `division=${reportDivision}`
            let data = reportDownload(HINDERANCE_REPORT, query)

            // window.open(`${HINDERANCE_REPORT}?division=${reportDivision}`, 'rel=noopener noreferrer');
        } else if (report === 'Letter Pdf' && reportDivision !== '') {
            let query = `division=${reportDivision}`
            let data = reportDownload(LETTER_PDF_GENRATE, query)

            // window.open(`${LETTER_PDF_GENRATE}?division=${reportDivision}`, 'rel=noopener noreferrer')
        } else if (report === 'Bill Pdf' && reportDivision !== '') {

            let query = `division=${reportDivision}`
            let data = reportDownload(BILL_PDF_GENRATE, query)

            // window.open(`${BILL_PDF_GENRATE}?division=${reportDivision}`, 'rel=noopener noreferrer');
        } else if (report === 'Action Taken Report' && reportDivision !== '') {
            let query = `division=${reportDivision}`
            let data = reportDownload(REVISITE_REPORT, query)

            // window.open(`${REVISITE_REPORT}?division=${reportDivision}`, 'rel=noopener noreferrer');
        }
    }

    return (
        <div className='w-full'>
            <h1 className='text-left text-blue text-sm font-bold md:p-4 py-4 md:-ml-5'>Download All Reports</h1>
            <div className='w-full space-y-2 md:space-y-0 md:flex md:space-x-2 px-2 mb-4'>
                <ReportDropDown
                    id='division'
                    name='Select Report Type'
                    lable='Select Report Type'

                    onChange={handleState}
                    value={report || ''}
                    
                />

                <DivisionMain_6 />


            </div>
            <div className='w-full flex justify-center my-3'>
                <Button className=' bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold'
                    onClick={handleOtherReport}
                    disabled={reportDivision === undefined}>
                    <Download /> <span>Download Report</span>
                </Button>
            </div>
        </div>
    )
}

export default AllReport
