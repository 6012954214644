import { useQueryResponse } from './Context/Core/QueryResponseProvider'
import DistrictFilter from '../DropDown'
import { QueryRequestProvider, QueryResponseProvider, VillageDropDownMainState } from './Context'

import React, { useEffect, useMemo, useState } from 'react'
import { useQueryResponseLoading } from './Context/index'
import DropDown from '../DropDown'
import { stat } from 'fs'
import { useDispatch, useSelector } from 'react-redux'
import { dropDownReducerState, villageDropdownState } from 'src/redux/Slicer/dropdownSlicer'
import { RootState } from 'src/redux/store'



type Props = {}

const Main = (props: Props) => {
    const [selecteVal, setSelectVal] = useState<string>('')
    const { response } = useQueryResponse();
    const isLoading = useQueryResponseLoading();
    const { state, updateState } = VillageDropDownMainState();
    const apiResponse: any = useMemo(() => response, [response]);
    const tableData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? (apiResponse?.data ? apiResponse?.data.filter((cur: string) => cur !== '') : []) : []),
        [apiResponse, isLoading]
    );

    const { calendeChange, downloadReportDivision } = useSelector((state: RootState) => dropDownReducerState(state))
    const dispatch = useDispatch()
    const handleState = (val: string) => {

        setSelectVal(val)
        dispatch(villageDropdownState(val))
    }

    useEffect(() => {
        updateState({
            filter: {
                division: downloadReportDivision
            }
        })
    }, [downloadReportDivision]);

    return (
        <div className='w-full'>
            <DropDown
                id='villageFilter'
                name='Select Name of Village'
                lable='Name of Village'
                jsonData={tableData || []}
                onChange={handleState}
                value={selecteVal || ''}
                isLoading={isLoading}
            />
        </div>
    )
}

const VillageMain: React.FC<Props> = ({ }) => {

    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default VillageMain
