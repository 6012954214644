import axios, { AxiosResponse } from 'axios'
import { FINICIAL_PROGRESSS } from 'src/service/apis'

const getFinicialProgDropDown = () => {
    return axios
        .get(`${FINICIAL_PROGRESSS}`
           

        )
        .then((d: AxiosResponse<any>) => d.data)
}

export { getFinicialProgDropDown }