import { useQueryResponse } from './Context/Core/QueryResponseProvider'
import DistrictFilter from '../DropDown'
import { QueryRequestProvider, QueryResponseProvider, FinicialProDropDownMainState } from './Context'

import React, { useMemo, useState } from 'react'
import { useQueryResponseLoading } from './Context/index'
import DropDown from '../DropDown'
import { useDispatch } from 'react-redux'
import { finacialProDropDownState } from 'src/redux/Slicer/dropdownSlicer'




type Props = {}

const Main = (props: Props) => {
    const [selecteVal, setSelectVal] = useState<string>('')
    const { response } = useQueryResponse();
    const isLoading = useQueryResponseLoading();
    const { state, updateState } = FinicialProDropDownMainState();
    const apiResponse: any = useMemo(() => response, [response]);
    const tableData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? (apiResponse?.data ? apiResponse?.data.filter((cur: string) => cur !== '' && cur !== 'null') : []) : []),
        [apiResponse, isLoading]
    );

    const dispatch = useDispatch()
    const handleState = (val: string) => {
        // updateState({
        //     filter: {
        //         val: val
        //     }
        // })
        setSelectVal(val)
        dispatch(finacialProDropDownState(val))
    }


    return (
        <div className='w-full'>
            <DropDown
                id='finProgress'
                name='Select Finicial Progress'
                lable='Financial Progress'
                jsonData={tableData || []}
                onChange={handleState}
                value={selecteVal || ''}
                isLoading={isLoading} />
        </div>
    )
}

const FinicialProgressMains: React.FC<Props> = ({ }) => {

    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default FinicialProgressMains
