import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { stat } from "fs/promises"
import { RootState } from "../store"
import { getPichart } from "src/service/services"
import axios, { AxiosError } from "axios"
import { toast } from "react-toastify";
import { stringifyObjectToRequestQuery } from "src/helpers/index"
import dayjs from "dayjs"

let dates = new Date()
let initalDate = dayjs(dates).format("YYYY-MM-DD")
interface DropDownState {
    districtState: string | undefined,
    villageState: string | undefined,
    firmState: string | undefined
    isLoading: boolean,
    message: string | undefined
    error: boolean,
    dataChanged: boolean
    userName: string | undefined
    division: string | undefined
    reportDivision: string | undefined
    webReportDivision:string | undefined
    downloadReportDivision: string | undefined
    phyProgres: string | undefined
    finacialProgress: string | undefined,
    data: any[],
    calendeChange: string | undefined,
    monthYear: string,
    dashboardState: boolean,
  userNameListState:string|undefined
}

const currentDate = new Date();
const year = currentDate.toLocaleString('default', { year: '2-digit' });
const currentMonth = currentDate.toLocaleString('default', { month: 'short' });

const initialState: DropDownState = {
    districtState: undefined,
    villageState: undefined,
    firmState: undefined,
    isLoading: false,
    message: undefined,
    error: false,
    dataChanged: false,
    userName: undefined,
    division: undefined,
    reportDivision: undefined,
    downloadReportDivision: undefined,
    phyProgres: undefined,
    finacialProgress: undefined,
    data: [],
    calendeChange: initalDate,
    monthYear: `${currentMonth}-${year}`,
    dashboardState: false,
    webReportDivision:undefined,
    userNameListState:undefined
}

export const dashboard_data: any = createAsyncThunk(
    'chart/pichartData',
    async (_, thunkApi: any,) => {
        try {
            const getFilterState = thunkApi.getState().dropdown
            const query = {
                scheme_name: getFilterState.districtState,
                division: getFilterState.division,
                name_village: getFilterState.villageState
            }

            const queryparams = stringifyObjectToRequestQuery(query)
            // console.log(queryparams)
            const response = await getPichart(queryparams);

            return response.data;



        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                toast.error(error.response?.data.message)
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message

                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });


            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)


export const dropdownSlicer = createSlice({
    name: 'dropdownSlice',
    initialState,
    reducers: {
        districtDropdownState(state, action: PayloadAction<any>) {
            state.districtState = action.payload
        },
        villageDropdownState(state, action: PayloadAction<any>) {
            state.villageState = action.payload
        },
        userNameListDropdownState(state, action: PayloadAction<any>) {
            state.userNameListState = action.payload
        },
        firmDropDownState(state, action: PayloadAction<any>) {
            state.firmState = action.payload
        },
        updateCalender(state, action: PayloadAction<any>) {
            state.calendeChange = action.payload
        },
        userNameDropDownState(state, action: PayloadAction<any>) {
            state.userName = action.payload
        },
        divisionDropDownState(state, action: PayloadAction<any>) {
            state.division = action.payload
        },
        reportDivisionState(state, action: PayloadAction<any>) {
            state.reportDivision = action.payload
        },
        webReportDivisionState(state, action: PayloadAction<any>) {
            state.webReportDivision = action.payload
        },
        downloadReportDivisionState(state, action: PayloadAction<any>) {
            state.downloadReportDivision = action.payload
        },
        phyProgressDropDownState(state, action: PayloadAction<any>) {
            state.phyProgres = action.payload
        },
        finacialProDropDownState(state, action: PayloadAction<any>) {
            state.finacialProgress = action.payload
        },
        monthYearState(state, action: PayloadAction<any>) {
            state.monthYear = action.payload
        },
        updateDashboardState(state, action: PayloadAction<any>) {
            state.dashboardState = action.payload
        },
        resetChangedState(state) {
            state.dataChanged = false;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },


    },
    extraReducers: (builder) => {
        builder.addCase(dashboard_data.fulfilled, (state, action: PayloadAction<any>) => {
            state.data = action.payload;
            state.isLoading = false
            // console.log(action.payload)
        });
        builder.addCase(dashboard_data.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(dashboard_data.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = action.payload || 'Error Occured'
        });

    }

})

export const {
    districtDropdownState,
    villageDropdownState,
    firmDropDownState,
    userNameDropDownState,
    finacialProDropDownState,
    divisionDropDownState,
    phyProgressDropDownState, setError, resetError,userNameListDropdownState, resetChangedState, updateCalender, monthYearState, updateDashboardState, reportDivisionState, downloadReportDivisionState,webReportDivisionState } = dropdownSlicer.actions;

export const dropDownReducerState = (state: RootState) => state.dropdown;

export default dropdownSlicer.reducer
