import React from 'react'
import DivisionMain from 'src/components/ui/Common/Filter/Division/DivisionMain';
import VillageMain from 'src/components/ui/Common/Filter/Village/VillageMain';
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { Button } from 'src/components/ui/button';
import { Download } from 'lucide-react';
import { dropDownReducerState } from 'src/redux/Slicer/dropdownSlicer';
import { reportDownload } from 'src/service/services';
import { PHYSICAL_PROGRESS_REPORT_PDF } from 'src/service/apis';


const PhyProgressReport = () => {
    const { monthYear, reportDivision, calendeChange, downloadReportDivision, division, villageState } = useSelector((state: RootState) => dropDownReducerState(state))
    const handlePhyProgressReport = () => {
        // window.open(`${PHYSICAL_PROGRESS_REPORT_PDF}?division=${division}&village_name=${villageState}`, 'rel=noopener noreferrer');
        let query = `division=${division}&village_name=${villageState}`
        let data = reportDownload(PHYSICAL_PROGRESS_REPORT_PDF, query)

    }
    return (
        <div className='w-full'>
            <h1 className='text-left ml-2 text-blue text-sm font-bold p-4'>Download Physical Progress Report</h1>
            <div className='w-full space-y-2 md:space-y-0 md:flex md:space-x-2 px-6 mb-4'>
                <DivisionMain />
                <VillageMain />
            </div>

            <div className='w-full flex justify-center my-3'>
                <Button className=' bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold'
                    onClick={handlePhyProgressReport}
                    disabled={division === undefined || villageState === undefined}>
                    <Download /> <span>Download Report</span>
                </Button>
            </div>
        </div>
    )
}

export default PhyProgressReport
