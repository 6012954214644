import React, { useState } from 'react'


import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { dropDownReducerState } from 'src/redux/Slicer/dropdownSlicer'
import { reportDownload, reportDownloadWithoutDivision } from 'src/service/services'
import { VIEW_WEB_BILL_PDF, VIEW_WEB_FACTORY_INSPECTION_PDF, VIEW_WEB_LEETER_PDF } from 'src/service/apis'
import WebPdfReportDropDown from './WebPdfReportDropDown'
import ButtonsComponent from './ButtonsComponent'
import DivisionMainWebReport from 'src/components/ui/Common/Filter/Division_1/DivisionMainWebReport'
const WebpdfReport = () => {
    const [report, setReport] = useState('Letter Pdf')
    const handleState = (val: string) => {
        setReport(val)
    }

    const { webReportDivision } = useSelector((state: RootState) => dropDownReducerState(state))
console.log(webReportDivision)
    const handleOtherReport = () => {
        if (report === 'Letter Pdf' && webReportDivision !== '') {
            let query = `division=${webReportDivision}`
            let data = reportDownload(VIEW_WEB_LEETER_PDF, query)

         
        } else if (report === 'Factory Inspection Pdf') {
           
            let data = reportDownloadWithoutDivision(VIEW_WEB_FACTORY_INSPECTION_PDF)

       
        } else if (report === 'Bill Pdf' && webReportDivision !== '') {

            let query = `division=${webReportDivision}`
            let data = reportDownload(VIEW_WEB_BILL_PDF, query)

           
        }
    }

    return (
        <div className='w-full'>
            <h1 className='text-left text-blue text-sm font-bold md:p-4 py-4 md:ml-5'>Download Web Reports</h1>
            <div className='w-full space-y-2 md:space-y-0 md:flex md:space-x-2 px-2 mb-4'>
                <WebPdfReportDropDown
                    id='division'
                    name='Select Report Type'
                    lable='Select Report Type'

                    onChange={handleState}
                    value={report || ''}
                />
                {
                    report === 'Factory Inspection Pdf' ? <></> : <DivisionMainWebReport />
                }



            </div>
            <div className='w-full flex justify-center my-3'>



                {report === 'Factory Inspection Pdf' ? <ButtonsComponent disabled={false} handleOtherReport={handleOtherReport} name='Download Report' /> :
                    <ButtonsComponent disabled={webReportDivision === undefined} handleOtherReport={handleOtherReport} name='Download Report' />}

            </div>
        </div>
    )
}

export default WebpdfReport
