
import DashboardMain from '../modules/Dashboard/DashboardMain'
import { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import ReportMain from '../modules/Reports/ReportMain'
import LetterIssueMain from '../modules/LetterIssue/LetterIssueMain'
import ContracterBillMain from '../modules/ContracteBill/ContracterBillMain'
import AppLayout from '../modules/Layout/AppLayout'
import DownloadReportMain from '../modules/DownloadReport/DownloadReportMain'
import AvaibilityOfContracterMain from '../modules/AvaibilityOfContracter/AvaibilityOfContracterMain'
import UploadReportMain from '../modules/UploadReport/UploadReportMain'



const AuthRoutes = () => {
    return (
        <Routes >
            <Route element={<AppLayout />}>
                {/* <Route path="pages" element={<DashboardMain />} /> */}
                <Route path="dashboard" element={<DashboardMain />} />
                <Route path='report' element={<ReportMain />} />
                <Route path='download' element={<DownloadReportMain />} />
                <Route path='contractor' element={<AvaibilityOfContracterMain />} />
                <Route path='upload-file' element={<UploadReportMain/>}/>
                {/* <Route path='form/letter-issue' element={<LetterIssueMain />} />
                <Route path='form/contracter-bill' element={<ContracterBillMain />} /> */}
                <Route index element={<DashboardMain />} />
            </Route>
        </Routes>
    )
    
}

export default AuthRoutes

