import ProgressBar from '../ReportCom/ProgressBar'
import { Progress } from '@radix-ui/react-progress'
import React, { useMemo } from 'react'
interface FinicialProgress {
    financialProgress: string
}
const FinicialProgress = ({ financialProgress }: FinicialProgress) => {
    const val = useMemo(() => financialProgress === null ? '-' : (financialProgress.includes('%') ? financialProgress.split('%')[0] : financialProgress), [financialProgress])



    return (
        <div className='w-full z-0'>
            {val === '-' ? '-' : (<div className='w-full space-y-1'>
                <h1 className='ml-2'>{val}%</h1>
                <ProgressBar val={val} />
            </div>)}

        </div>
    )
}

export default FinicialProgress
