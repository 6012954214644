
import React, { useState } from 'react'
import { Card } from 'src/components/ui/card'
import ProgressReport from '../Core/ProgressReport'
import AllReport from '../Core/AllReport'
import PhyProgressReport from '../Core/PhyProgressReport'
import ContractorReport from '../Core/ContractorReport'
import WebpdfReport from '../Core/WebpdfReport'


const DownloadReport = () => {

    return (
        <div className='w-full p-2 mt-10 md:mt-6 lg:mt-0 h-full '>
            <div className='w-full px-8 py-8 bg-cardBg  rounded-[30px] flex justify-center items-center  md:rounded-[30px]  h-full  lg:my-0 border border-white'>
                <Card className=' w-full md:w-3/4 rounded-3xl h-full overflow-scroll no-scrollbar'>
                    <div className='w-full'>
                        <ProgressReport />
                    </div>


                    <div className='w-full border-t border-t-blue border-dotted bg-blue bg-opacity-30 p-4 '>
                        <AllReport />

                    </div>


                    <div className='w-full border-t border-t-blue border-dotted'>
                        <PhyProgressReport />
                    </div>

                    <div className='w-full border-t border-t-blue border-dotted bg-blue bg-opacity-30 p-4 '>

                        <ContractorReport />
                    </div>
                    <div className='w-full border-t border-t-blue border-dotted'>
                        <WebpdfReport />
                    </div>

                </Card>
            </div>

        </div>
    )
}

export default DownloadReport
