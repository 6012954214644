import { AlignJustify } from 'lucide-react';
import { MenuOptions } from '../Sidebar/modules';
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import Logo from 'src/assets/Images/Logo.svg'
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { dropDownReducerState, updateDashboardState } from 'src/redux/Slicer/dropdownSlicer';

const Headers = () => {
    const { pathname } = useLocation();
    const menuOptions = MenuOptions;


    const heading = useMemo(() => {
        for (const cur of menuOptions) {
            if (cur.path && pathname.includes(cur.path)) {
                return cur.name;
            } else if (cur.options && cur.options.length > 0) {
                for (const ind of cur.options) {
                    if (ind.path && pathname.includes(ind.path)) {
                        return `${cur.name}-${ind.name}`;
                    }
                }
            }
        }
        return ''; // Default case if no match found
    }, [menuOptions, pathname]);
    const { dashboardState } = useSelector((state: RootState) => dropDownReducerState(state))
    const dispatch = useDispatch();


    const handleClick = () => {
        console.log('click')
        // if (dashboardState ) {
        //     dispatch(updateDashboardState(false))
        // } else {
        //     dispatch(updateDashboardState(true))
        // }
        dispatch(updateDashboardState(!dashboardState))
    }

    return (
        <div className='w-full h-[10%]  fixed top-0 z-20  flex items-center bg-white space-x-4 md:space-x-0'>
            <AlignJustify size={32} className='md:invisible ml-2 z-50 text-legentsssss' onClick={handleClick} />
            <div className='w-0 md:w-[16.66%]  md:flex lg:items-center  py-4   px-2 hidden '>

                <img src={Logo} alt='logo' className='w-full -ml-10 p-2' />

            </div>



            <h1 className='text-xl font-bold text-legent'>{heading}</h1>
        </div>
    )
}

export default Headers
