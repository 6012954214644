import dayjs from "dayjs";
import * as Yup from "yup";

export interface FactoryInspectionProps {
  
    location: string;
    remark: string | undefined;

    pdf1: File | null;
 
    dt: string
    inspection_condected_by: string | undefined
    purpose_of_inspection: string | undefined
}




export const FactoryInspectionInitialValue: FactoryInspectionProps = {
    purpose_of_inspection: "",
    inspection_condected_by: "",
    location: "",
    pdf1: null,
   
    dt: dayjs().format('YYYY-MM-DD') as string,
    remark: ''

};

export const FactoryInspectionValidationSchema = Yup.object().shape({
  purpose_of_inspection: Yup.string().required("Field is required"),
    dt: Yup.string().required("Field is required").nullable(),
    inspection_condected_by: Yup.string().required("Field is required"),
    location: Yup.string().required("Field is required"),
    remark: Yup.string().required("Field is required"),
   
    pdf1: Yup.mixed()
        .required('Field is required')
        .test('fileFormat', 'Only PDF files are allowed', value => {
            if (value) {
                const file = value as File;
                const supportedFormats = ['pdf'];
                return supportedFormats.includes(file.name.split('.').pop()?.toLowerCase() ?? '');
            }
            return true;
        })
        .test('fileSize', 'File size must be less than 15MB', value => {
            if (value) {
                const file = value as File;
                // Convert size to bytes if necessary
                const fileSizeInBytes = file.size;
                return fileSizeInBytes <= 15728640; // 1MB in bytes
            }
            return true;
        }),
  
});

