


import { Popover, PopoverTrigger } from "@radix-ui/react-popover"
import { CalendarIcon } from "lucide-react"

import { format } from "date-fns"
import { useEffect, useState } from "react"
import { Button } from "../../button"
import { cn } from "src/helpers"
import { PopoverContent } from "../../popover"
import { Calendar } from "../../calendar"
import dayjs from "dayjs"
import { Label } from 'src/components/ui/label'
interface CalenderFilterProps {
    dts: any,
    // setDate:(dt:Date)=>void,
    onchange: (dt: any) => void
    errorMessage:string
}


const CalenderFilter = ({ dts, onchange,errorMessage }: CalenderFilterProps) => {
    const [date, setDate] = useState<Date>()
    const [datestring, setDateString] = useState('')
    const handleValueChange = (selectedValue: any) => {
        setDate(selectedValue)
        if (onchange) {
            // onchange(selectedValue);
            onchange(dayjs(new Date(selectedValue)).format('YYYY-MM-DD'))
        }

    };
   
    useEffect(() => {
        if (dts) {
            // console.log({dts});
            let [year, month, day]: any = dts && dts?.toString().split('-')
            // console.log({year, month, day});
            
            setDate(new Date(dts))
        }
    }, [])

    useEffect(() => {
        let dt = date ? dayjs(new Date(date)).format('DD MMM,YYYY dddd') : "Pick a date";
        setDateString(dt)
    }, [date])

    return (<div className="w-full flex flex-col mt-1">
       <Label  className={`text-legent text-sm font-semibold ml-2 `}>Date</Label>
       <div>
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    variant={"outline"}
                    className={cn(
                        "w-full justify-between text-left font-normal bg-white rounded-full border border-input border-[#C6C6C6]",
                        !date && "text-muted-foreground"
                    )}
                >
                    <span className="text-xs font-medium text-legent">{datestring}</span>
                    <CalendarIcon className="mr-2 h-4 w-4" />

                </Button>
               
            </PopoverTrigger>
           
            <PopoverContent className="">
                <Calendar
                    mode="single"
                    selected={date}
                    onSelect={handleValueChange}
                    initialFocus

                />
            </PopoverContent>
        </Popover>
        </div>
        <p className='text-sm text-red-600 ml-2 font-medium'>{errorMessage}</p>
        </div >
    )
}
export default CalenderFilter