import { Download } from 'lucide-react'
import React from 'react'
import { Button } from 'src/components/ui/button'

interface ButtonsComponentProps{
    handleOtherReport:()=>void
    disabled:boolean
    name:string
}

const ButtonsComponent = ({handleOtherReport,disabled,name}:ButtonsComponentProps) => {
  return (
    <div>
        <Button className=' bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold'
                        onClick={()=>handleOtherReport()}
                        disabled={disabled}
                    >
                        <Download /> <span>{name}</span>
                    </Button>
    </div>
  )
}

export default ButtonsComponent