import axios, { AxiosResponse } from 'axios'
import { USER_LIST, VILLAGE_NAME } from 'src/service/apis'

const getUserListDropDown = (query: string) => {
    return axios
        .get(`${USER_LIST}?${query}`
           

        )
        .then((d: AxiosResponse<any>) => d.data)
}

export { getUserListDropDown }