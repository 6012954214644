import axios, { AxiosResponse } from "axios";
import { PIECHAT_API_URL, UPLOAD_FILE_PDF } from "./apis";
import { toast } from "react-toastify";

export function getPichart (query:string): Promise<AxiosResponse<any>> {
    return axios.get(`${PIECHAT_API_URL}?${query}`).then((d: AxiosResponse) => 
  
    d.data
    ) 
  }


  // download pdf function
//   export function reportDownload(baseUrl: string, query: string,setLoading?:(val:boolean)=>void): Promise<AxiosResponse<any>> {
//     return axios.get(`${baseUrl}?${query}`).then((d: AxiosResponse) => {
//         setLoading&& setLoading(true)
//         if (d.data?.code === 404) {
//             setLoading&& setLoading(false)
//             toast.error(d.data?.msg);
//             return Promise.resolve({} as AxiosResponse<any>);
//         } else {
//             setLoading&& setLoading(false)
//             window.open(`${baseUrl}?${query}`,'rel=noopener noreferrer');
//             return Promise.resolve({} as AxiosResponse<any>);
//         }
//     });
// }
export function reportDownload(baseUrl: string, query: string, setLoading?: (val: boolean) => void): Promise<AxiosResponse<any>> {
    setLoading && setLoading(true); // Start the loader before making the request
    
    // Open a temporary window immediately
    const newWindow = window.open('', '_blank');

    return axios.get(`${baseUrl}?${query}`)
        .then((d: AxiosResponse) => {
            if (d.data?.code === 404) {
                toast.error(d.data?.msg);
                if (newWindow) {
                    newWindow.close(); // Close the temporary window if an error occurs
                }
                return Promise.resolve({} as AxiosResponse<any>);
            } else {
                if (newWindow) {
                    // Update the URL of the new tab
                    newWindow.location.href = `${baseUrl}?${query}`;
                }
                return Promise.resolve({} as AxiosResponse<any>);
            }
        })
        .catch((error) => {
            toast.error("Error downloading report");
            if (newWindow) {
                newWindow.close(); // Close the temporary window if an error occurs
            }
            return Promise.reject(error);
        })
        .finally(() => {
            setLoading && setLoading(false); // End the loader after the request completes
        });
}
export function reportDownloadWithoutDivision(baseUrl: string,): Promise<AxiosResponse<any>> {
    return axios.get(`${baseUrl}`).then((d: AxiosResponse) => {
        if (d.data?.code === 404) {
            toast.error(d.data?.msg);
            return Promise.resolve({} as AxiosResponse<any>);
        } else {
            window.open(`${baseUrl}`,'rel=noopener noreferrer');
            return Promise.resolve({} as AxiosResponse<any>);
        }
    });
}

export const postUploadFileApi = (postData: any) => {
    return axios
      .post(`${UPLOAD_FILE_PDF}`, postData)
      .then((d: AxiosResponse<any>) => d?.data);
  };




  