type ReportType={
    label:string,
    value:string
}

export const reportType:ReportType[]=[
    {
        label:'Hindrance Report',
        value:'hindranceReport'
    },
    {
        label:'Letter Pdf',
        value:'letterPdf'
    },
    {
        label:'Bill Pdf',
        value:'billPdf'
    },
    {
        label:'Action Taken Report',
        value:'actionTakenReport'
    },
]

export const webReport :ReportType[]=[
    {
        label:'Letter Pdf',
        value:'Letter Pdf'
    },
    {
        label:'Bill Pdf',
        value:'Bill Pdf'
    },
    {
        label:'Factory Inspection Pdf',
        value:'Factory Inspection Pdf'
    },
]