"use client"

import React from "react"

import { Calendar } from "../calendar"
import { Button } from '../button'
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "../popover"
import dayjs from "dayjs"
import { CalendarDays } from "lucide-react"



interface CalenderCompProps {
    heading?: string

    setDatevalue: (value: string) => void;
    yesterdayDate?: any

}

const CalenderComp = ({ heading, setDatevalue }: CalenderCompProps) => {


    const [date, setDate] = React.useState<Date | undefined>(new Date)
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const handleDateSelect = (selectedDate: Date | undefined) => {

        setIsOpen(false);
        setDate(selectedDate);
        setDatevalue(dayjs(selectedDate).format("YYYY-MM-DD"))


    };

    return (
        <div className="w-full mt-6 ">
            <h1 className="text-sm font-semibold text-legent ml-2 text-left">{heading}</h1>

            <Popover open={isOpen} onOpenChange={(open: boolean) => setIsOpen(open)}>
                <PopoverTrigger asChild>
                    <Button variant="outline" className="w-full rounded-full flex justify-between px-4 bg-white">{dayjs(date).format("YYYY-MM-DD")} <CalendarDays size={16} /></Button>
                </PopoverTrigger>
                <PopoverContent className="">
                    <Calendar
                        mode="single"
                        selected={date}
                        onSelect={handleDateSelect}
                        className="rounded-md text-sm "
                    />
                </PopoverContent>
            </Popover>
        </div>
    )
}

export default CalenderComp