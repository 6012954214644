import React, { useMemo, useState } from 'react';
import { useQueryResponseLoading, useQueryResponse } from './Context';
import DropDown from '../DropDown';
import { QueryRequestProvider, QueryResponseProvider } from './Context';

type Props = {
    dropDownChange: (val: any) => void;
    erroMessage?:string,
    values:string
};

const Main: React.FC<Props> = ({ dropDownChange,values,erroMessage }) => {
    const [selecteVal, setSelectVal] = useState<string>(values);
    
    const { response } = useQueryResponse();
    const isLoading = useQueryResponseLoading();
   
    const apiResponse: any = useMemo(() => response, [response]);
    const tableData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? (apiResponse?.data ? apiResponse?.data.filter((cur: string) => cur !== '') : []) : []),
        [apiResponse, isLoading]
    );

    const handleState = (val: string) => {
        setSelectVal(val);
        dropDownChange(val);
    };

    return (
        <div className='w-full'>
            <DropDown
                id='division'
                name='Select Division'
                lable='Division'
                jsonData={tableData || []}
                onChange={handleState}
                value={selecteVal }
                isLoading={isLoading}
            />

        
        </div>
    
    );
};

const DivisionForFileUpload: React.FC<Props> = ({ dropDownChange,erroMessage,values }) => {
  
    return (
        <>
        <QueryRequestProvider>
            <QueryResponseProvider>
                <div className='w-full'>
                <Main dropDownChange={dropDownChange} values={values} />
                {
            erroMessage?<p className='text-sm text-red-600 ml-2 font-medium'>{erroMessage}</p>:<></>}
                </div>
            </QueryResponseProvider>
        </QueryRequestProvider>
        </>
    );
};

export default DivisionForFileUpload;
