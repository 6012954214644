import { BookUser, Download, History, LayoutDashboard, Upload, } from "lucide-react"

export interface IMenuItem {
    name: string,
    iconName: any,
    path?: string,
    options?: Array<{
        name: string,
        iconName: any,
        path: string,
    }>
}

export interface IMenu {
    heading: string,
    options?: Array<{
        name: string,
        iconName: any,
        path: string,
    }>
}

export const MenuOptions: IMenuItem[] = [
    {
        name: "Dashboard",
        iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
        path: "dashboard",

    },
    {
        name: "History",
        iconName: (className: string) => <History size={16} className={className} />,
        path: "report"
    },
    // {
    //     name: "Form",
    //     iconName: (className: string) => <FileText size={16} className={className} />,
    //     // path: "/form/letter-issue",
    //     options: [
    //         {
    //             name: "Letter Issue Form",
    //             iconName: (className: string) => <FileJson size={16} className={className} />,
    //             path: "form/letter-issue"
    //         },
    //         {
    //             name: "Contarcter Bill Form",
    //             iconName: (className: string) => <Receipt size={16} className={className} />,
    //             path: "form/contracter-bill"
    //         },
    //     ]
    // }
    {
        name: "Download Reports",
        iconName: (className: string) => <Download size={16} className={className} />,
        path: "download"
    },
    {
        name: "Availability Of Contractors",
        iconName: (className: string) => <BookUser size={16} className={className} />,
        path: "contractor"
    },
    {
        name: "Upload Files",
        iconName: (className: string) => <Upload size={16} className={className} />,
        path: "upload-file"
    },
]