import ProgressBar from '../ReportCom/ProgressBar'
import { Progress } from '@radix-ui/react-progress'
import React, { useMemo } from 'react'
interface FinicialProgress {
    physicalPrgress: string
}
const PhysicalProgress = ({ physicalPrgress }: FinicialProgress) => {
    // const val = useMemo(() => physicalPrgress && physicalPrgress == '' ? '-' : (physicalPrgress.includes('%') ? physicalPrgress.split('%')[0] : physicalPrgress), [physicalPrgress])
    const val = useMemo(() => {
        if (physicalPrgress === null) {
            return '-';
        } else if (physicalPrgress === '') {
            return '-'
        } else if (physicalPrgress.includes('%')) {
            return physicalPrgress.split('%')[0]
        } else {
            return physicalPrgress
        }

    }, [physicalPrgress]);


    return (
        <div className='w-full z-0'>
            {val === '-' ? '-' : (<div className='w-full space-y-1'>
                <h1 className='ml-2'>{val}%</h1>
                <ProgressBar val={val} />
            </div>)}

        </div>
    )
}

export default PhysicalProgress
