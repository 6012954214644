

import { ColumnDef } from "@tanstack/react-table"
import { Check, X } from "lucide-react"



export type ReportTable = {
    qc_name: string,
    contractor_name: string,
    contractor_contact_no: string,
    contractor_designation: string
}


export const column: ColumnDef<ReportTable>[] = [
    {
        accessorKey: "qc_name",
        header: "Qc Name",
    },
    {
        accessorKey: "contractor_name",
        header: "Contractor Name",

    },
    {
        accessorKey: "contractor_designation",
        header: "Designation",
    },
    {
        accessorKey: "contractor_contact_no",
        header: "Contractor Number",
    },

]

