import dayjs from "dayjs";
import * as Yup from "yup";

export interface LetterProps {
    issuedTo: string;
    issuedForm: string;
    letterNo: string | undefined;
    letterPurpose: string | undefined;
    pdf1: File | null;
    division: string;
    dt:string
}


export const LetterInitialValue: LetterProps = {
    issuedTo: "",
    issuedForm: "",
    letterNo: "",
    letterPurpose: "",
    pdf1: null,
    division: '',
    dt: dayjs().format('YYYY-MM-DD') as string
};

export const LetterValidationSchema = Yup.object().shape({
    issuedTo: Yup.string().required("Field is required"),
    dt: Yup.string().required("Field is required").nullable(),
    issuedForm: Yup.string().required("Field is required"),
    letterNo: Yup.string().required("Field is required"),
    letterPurpose: Yup.string().required("Field is required"),
    pdf1: Yup.mixed()
        .required('Field is required')
        .test('fileFormat', 'Only PDF files are allowed', value => {
            if (value) {
                const file = value as File;
                const supportedFormats = ['pdf'];
                return supportedFormats.includes(file.name.split('.').pop()?.toLowerCase() ?? '');
            }
            return true;
        })
        .test('fileSize', 'File size must be less than 15MB', value => {
            if (value) {
                const file = value as File;
                // Convert size to bytes if necessary
                const fileSizeInBytes = file.size;
                return fileSizeInBytes <= 15728640; // 1MB in bytes
            }
            return true;
        }),
    division: Yup.string().required("Field is required"),
});

