
import { Label } from 'src/components/ui/label'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger } from 'src/components/ui/select'
import React, { SelectHTMLAttributes, useMemo } from 'react'
import { cn } from 'src/helpers';
import { webReport } from './modules';

interface DropdownProps {
    id: string,
    lableClass?: string,
    lable?: string,
    jsonData?: string[],
    name: string
    value?: string
    isLoading?: boolean
    onChange?: (value: string) => void,
}


const WebPdfReportDropDown: React.FC<DropdownProps> = ({ id, lableClass, lable, jsonData, name, value, onChange, isLoading }) => {
    const val = useMemo(() => value !== undefined ? value : '', [value])
    return (
        <div className='w-full'>
            <Label
                htmlFor={id}
                className='w-full text-legent text-sm font-semibold ml-3'>{lable}</Label>
            <Select
                value={val || ""}
                onValueChange={onChange}
                disabled={isLoading}
            >
                <SelectTrigger>
                    {/* <SelectValue className="text-xs font-medium text-gray-500" placeholder={name} /> */}
                    <div className="text-xs font-medium text-gray-500 truncate">
                        {(typeof value === 'string' ? value?.slice(0, 70) : value) || name}
                    </div>
                </SelectTrigger>
                <SelectContent className=''>
                    <SelectGroup className=''>
                        <SelectLabel className="text-xs font-medium text-gray-500 truncate">{name}</SelectLabel>

                        {webReport.map((cur: any) => {

                            return <SelectItem
                                className="text-xs font-medium text-gray-700 truncate "
                                value={cur.label} key={`${cur.value}`}>{cur.label}</SelectItem>
                        })}

                    </SelectGroup>

                </SelectContent>
            </Select>
        </div>
    )
}

export default WebPdfReportDropDown
