import Factory from './Component/Factory'
import React from 'react'

const FactoryMain = () => {
  return (
    <div className='w-full'>
      <Factory />
    </div>
  )
}

export default FactoryMain