import { ArrowLeft, MoveLeft, Waves, X } from 'lucide-react'
import Menu from './Menu'
import { MenuOptions } from './modules'
import React from 'react'
import Logo from 'src/assets/Images/Logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { dropDownReducerState, updateDashboardState } from 'src/redux/Slicer/dropdownSlicer';

const SideBarComp = () => {
    const menuOptions = MenuOptions;
    const { dashboardState } = useSelector((state: RootState) => dropDownReducerState(state))
    const dispatch = useDispatch()
    return (
        <div className={` w-1/2 md:w-1/6 md:top-[11%] z-30 md:z-0   md:visible  h-screen bg-cardBg md:rounded-[40px] fixed ${dashboardState ? 'visible  duration-300 ease-linear' : 'invisible duration-300 transition ease-linear'} `}>
            <div className='md:hidden z-10 block w-full md:w-0 relative'>
                <div className={`w-full md:w-0  flex  justify-between py-4   px-2  md:invisible ${dashboardState ? 'visible ' : 'invisible'}`}>

                    <img src={Logo} alt='logo' className='w-32  ' />

                </div>
                <div className={`w-full flex justify-end md:w-0 md:invisible absolute -mt-14 ${dashboardState ? 'visible' : 'invisible'}`}>
                    <X size={32} strokeWidth={1} onClick={() => dispatch(updateDashboardState(!dashboardState))} className=' text-legent' />
                </div>

            </div>
            <div className='w-full py-2 pl-2 space-y-2 lg:mt-4'>
                {menuOptions.map((menu, index) => <Menu key={`${menu.name}`}
                    name={menu.name}
                    iconName={menu.iconName}
                    path={menu.path}
                    options={menu.options}
                />)}
            </div>
        </div>
    )
}

export default SideBarComp
