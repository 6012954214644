import { Input } from 'src/components/ui/input'
import React, { useRef, useState } from 'react'
import { Eye, FolderUp, Loader2 } from 'lucide-react'
import { useFormik } from 'formik';

import { Button } from 'src/components/ui/button';

import InputComp from 'src/components/ui/Common/InputsComponents/InputComp';


import { postUploadFileApi } from 'src/service/services';
import { ENUM_STATUS_CODE } from 'src/helpers/index';
import axios, { AxiosError } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateError, updateErrorCode, updateSuccessMess } from 'src/redux/Slicer/errorHadlingSlicer';



import CalenderFilter from 'src/components/ui/Common/Datepicker/CalenderFilter';

import { FactoryInspectionValidationSchema, FactoryInspectionInitialValue, FactoryInspectionProps } from './factoryModule';
import TextAreaComponent from 'src/components/ui/Common/TextAreaComponent/TextAreaComponent';




const Factory = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [dragging, setDragging] = useState(false);

    // const [open,setOpen]=useState<boolean>(false)
    const fileInputRef = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch()


    const handleIconClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    const formik = useFormik<FactoryInspectionProps>({
        initialValues: FactoryInspectionInitialValue,
        onSubmit: async (values, { resetForm, setValues }) => {


            let formData = new FormData();
            formData.append('purpose_of_inspection', values?.purpose_of_inspection || '');
            formData.append('inspection_condected_by', values.inspection_condected_by || '');
            formData.append('remark', values.remark || '');
            formData.append('location', values.location || '');

            formData.append('type', 'FACTORY');
            formData.append('dt', values?.dt);
            if (values.pdf1) {
                formData.append('pdf1', values.pdf1);
            }

            handleSendOtpApi(formData)
        },

        validationSchema: FactoryInspectionValidationSchema
    })

    // drag and drop feature

    const handleDragOver = (event: any) => {
        event.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        setDragging(false);
        const files = event.dataTransfer.files;
        if (files && files.length > 0) {

            formik.setFieldValue('pdf1', files[0])
        }
    };


    //   Api function calling
    const handleSendOtpApi = async (postData: any) => {
     
        setLoading(true)
        try {
            let apiResp = await postUploadFileApi(postData);
            dispatch(updateErrorCode(apiResp.code))
            if (apiResp.code === ENUM_STATUS_CODE?.SUCCESS) {


                formik.resetForm();
                dispatch(updateSuccessMess(apiResp?.msg))

            } else {

                dispatch(updateError(apiResp.msg))
                dispatch(updateErrorCode(apiResp.code))
                // toast.error(apiResp.message)
            }
            return apiResp.data
        } catch (err) {
            let error = err as Error | AxiosError
            if (axios.isAxiosError(error)) {
                dispatch(updateError(error.response?.data.msg))
                dispatch(updateErrorCode(error.response?.data.code))
                // toast.error(error.response?.data.message)
            }
            else {

                dispatch(updateError(error.message))
                // toast.error(error.message)

            }
        } finally {
            setLoading(false); // Set loading state to false when request completes (whether success or failure)
        }
    }



    return (
        <div className='w-full'>
            <form onSubmit={formik.handleSubmit}>


                <div className='w-full md:flex md:justify-between md:space-x-16'>
                    <div className='md:w-2/5 w-full'
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <div className='border-[3px] border-blue border-dashed h-52 w-full flex flex-col justify-center items-center'>
                            <div className='flex justify-center items-center'>
                                <FolderUp className='text-dashboardText  w-12 h-12 cursor-pointer' onClick={handleIconClick} />
                            </div>

                            <Input
                                type='file'
                                className='border border-input border-none hidden'
                                ref={fileInputRef}
                                // onChange={(e: any) => formik.setFieldValue('pdf1', e.target?.files[0])}
                                accept='.pdf'
                                onChange={(e: any) => {
                                    const file = e.target?.files[0];

                                    if (file && file.size < 15728640) {

                                        formik.setFieldValue('pdf1', file);

                                    } else {

                                        dispatch(updateError('File size less than 15MB.'))
                                    }
                                }}

                            />


                            <div className='text-[#737373] text-xs font-normal text-center'>Drag and drop your file</div>
                            <div className='text-dashboardText text-xs font-normal text-center'>{formik?.values?.pdf1?.name}</div>
                        </div>
                        <p className='text-sm text-red-600 ml-2 font-medium'>{formik.touched?.pdf1 && formik.errors?.pdf1 ? formik.errors?.pdf1 : ''}</p>
                        <div className='text-[#737373] text-xs font-normal w-full my-2 text-center'>
                            File should be in PDF format
                        </div>


                    </div>


                    <div className='w-full -mt-4'>


                        <div className='w-full md:grid md:grid-cols-2 md:gap-x-3 '>

                            <div className='mt-2'>
                                <CalenderFilter dts={formik?.values?.dt} errorMessage={formik.touched?.dt && formik.errors?.dt ? formik.errors?.dt : ''}
                                    onchange={(val: string) => formik?.setFieldValue('date', val)}
                                />
                            </div>



                            <InputComp
                                errorMessage={formik.touched?.location && formik.errors?.location ? formik.errors?.location : ''}
                                // regex={new RegExp('/[^0-9]+/g')}
                                value={formik?.values?.location}
                                onchange={(val: any) => formik.setFieldValue('location', val)}
                                type='text'
                                id='location'
                                lable='Location'
                                placeholder='Enter Location'
                            // maxLength={12}
                            />
                            <InputComp
                                errorMessage={formik.touched?.inspection_condected_by && formik.errors?.inspection_condected_by ? formik.errors?.inspection_condected_by : ''}
                                // regex={new RegExp('/[^0-9]+/g')}
                                value={formik?.values?.inspection_condected_by}
                                onchange={(val: any) => formik.setFieldValue('inspection_condected_by', val)}
                                type='text'
                                id='inspection_condected_by'
                                lable='Inspection conducted by'
                                placeholder='Enter Inspection conducted by'

                            />




                            <InputComp
                                errorMessage={formik.touched?.purpose_of_inspection && formik.errors?.purpose_of_inspection ? formik.errors?.purpose_of_inspection : ''}
                                // regex={new RegExp('/[^0-9]+/g')}
                                value={formik?.values?.purpose_of_inspection}
                                onchange={(val: any) => formik.setFieldValue('purpose_of_inspection', val)}
                                type='text'
                                id='purpose_of_inspection'
                                lable='Purpose of Inspection'
                                placeholder='Purpose of Inspection'
                            />


                        </div>
                        <div className='w-full'>
                            <TextAreaComponent
                                id='remark'
                                onchange={(val: string) => formik.setFieldValue('remark', val)}
                                value={formik.values.remark}
                                label='Remark'
                                placeholder='Enter Remark'
                                errorMessage={formik.touched?.remark && formik.errors?.remark ? formik.errors?.remark : ''}
                            />
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-center items-center mt-16'>
                    <Button className='lg:w-1/5 w-full bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold'
                        type='submit' disabled={loading}>
                        {loading ? (<> <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Please Wait...</span></>) : "Submit"}
                    </Button>
                </div>
            </form>

        </div>
    )
}

export default Factory
