import axios, { AxiosResponse } from 'axios'
import { DIVISION_URL } from 'src/service/apis'

const getDivisionDropDown = () => {
    return axios
        .get(`${DIVISION_URL}`
           

        )
        .then((d: AxiosResponse<any>) => d.data)
}

export { getDivisionDropDown }