import axios, { AxiosResponse } from 'axios'
import { DISTRICT_URL } from 'src/service/apis'

const getDistrictDropDown = (query: string) => {
    return axios
        .get(`${DISTRICT_URL}?${query}`
           

        )
        .then((d: AxiosResponse<any>) => d.data)
}

export { getDistrictDropDown }