import Bill from './Component/Bill'
import React from 'react'

const BillMain = () => {
  return (
    <div className='w-full'>
      <Bill/>
    </div>
  )
}

export default BillMain