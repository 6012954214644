import axios, { AxiosResponse } from 'axios'
import { PHYSICAL_PROGRESSS } from 'src/service/apis'

const getPhyProgressDropDown = () => {
    return axios
        .get(`${PHYSICAL_PROGRESSS}`
           

        )
        .then((d: AxiosResponse<any>) => d.data)
}

export { getPhyProgressDropDown }