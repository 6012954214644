
import React, { useEffect, useMemo, useState } from 'react'
import Block from '../Context/DashboardCom/Block'

import PieCharts from '../Context/DashboardCom/PieCharts'
import { Button } from 'src/components/ui/button'
import { Card } from 'src/components/ui/card'

import DistrictMain from 'src/components/ui/Common/Filter/District/DistrictMain'
import VillageMain from 'src/components/ui/Common/Filter/Village/VillageMain'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { dashboard_data, districtDropdownState, dropDownReducerState, updateCalender, } from 'src/redux/Slicer/dropdownSlicer'

import DivisionMain from 'src/components/ui/Common/Filter/Division/DivisionMain'

import dayjs from 'dayjs'

import { ChevronRight } from 'lucide-react'
import Execution from 'src/assets/Images/Execution.svg'
import BalanceWork from 'src/assets/Images/BalnceWork.svg';
import CompleatedWork from 'src/assets/Images/CompleatedWork.svg';
import TotalWork from 'src/assets/Images/TotalO&m.svg'
const Dashboard = () => {
  const { data, isLoading } = useSelector((state: RootState) => dropDownReducerState(state))
  const dispatch = useDispatch()

  const response: any = useMemo(() => isLoading ? [] : data, [, isLoading, data])

  const total: any = useMemo(() => isLoading ? undefined : (response ? response.total : undefined), [response, isLoading])
  const totalexecutionamount = useMemo(() => total && Object.entries(total).length > 0 ? total.totalexecutionamount : 0, [total])
  const totalbalanceworkorderamount = useMemo(() => total && Object.entries(total).length > 0 ? total.totalbalanceworkorderamount : 0, [total])
  const totalworkdoneamount = useMemo(() => total && Object.entries(total).length > 0 ? total.totalworkdoneamount : 0, [total])

  const totaloandmamount = useMemo(() => total && Object.entries(total).length > 0 ? total.totaloandmamount : 0, [total])

  const count: any = useMemo(() => isLoading ? [] : (response ? response?.count : []), [response, isLoading])
  const progress_count = useMemo(() => isLoading ? [] : (response ? response?.progress_count : []), [response, isLoading])



  useEffect(() => {
    dispatch(dashboard_data())
    return () => { }
  }, [])

  const handlechange = () => {

    dispatch(dashboard_data())
  }

  const dateChange = (val: string) => {
    dispatch(updateCalender(val))
  }
  return (
    <div className='w-full p-2 mt-10 md:mt-4 lg:mt-0 lg:h-full md:h-max'>
      <div className='w-full px-8 py-2 bg-cardBg rounded-[30px]  md:rounded-[30px]  h-full  lg:my-0 '>
        <div className='w-full grid grid-cols-1 lg:grid-cols-4 gap-x-4 gap-y-2 lg:gap-y-0  md:grid-cols-2 my-3'>
          <DivisionMain />
          <DistrictMain />
          <VillageMain />

          <div className='w-full flex justify-end'>
            <Button className='md:mt-6 w-full bg-goBackground gap-x-4 hover:bg-goBackground rounded-full text-xs font-semibold'
              onClick={handlechange} >GO <ChevronRight size={16} /></Button>
          </div>
        </div>

        <div className='w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-2 my-4 gap-y-4 lg:gap-y-0'>
          <Block heading='Total  Execution' value={totalexecutionamount} looding={isLoading} imge={Execution} />
          <Block heading='Total Balance Work Order' value={totalbalanceworkorderamount} looding={isLoading} imge={BalanceWork} />
          <Block heading='Total Completed work' value={totalworkdoneamount} looding={isLoading} imge={CompleatedWork} />
          <Block heading='Total O&M' value={totaloandmamount} looding={isLoading} imge={TotalWork} />
        </div>

        <div className='w-full grid grid-cols-1 lg:grid-cols-2 gap-x-2 gap-y-2 lg:gap-y-0'>
          <Card className='px-2 md:px-6 '>
            <h1 className='w-full text-center text-sm  mt-4 text-legent font-bold'> Work Progress</h1>
            <PieCharts
              piechartData={count || []}
              loading={isLoading}
              colors={['#1C95D4', '#B6B6B6', '#FE6464']}
              legentname='Work in progress' />
            {isLoading ? (<></>) : (<div className='w-full pb-2 space-y-2 flex flex-col md:flex-row justify-center items-center space-x-4'>

              <div className=' w-full flex  space-x-2 ml-4 md:ml-0 lg:mt-2'>
                <div className='w-4 h-4 rounded-full' style={{ backgroundColor: '#1C95D4' }}></div>
                <span className=' font-normal text-xs whitespace-nowrap  text-legent text-left'>In progress</span>
              </div>

              <div className='w-full  flex space-x-2'>
                <div className='w-4 h-4 rounded-full' style={{ backgroundColor: '#B6B6B6' }}></div>
                <span className=' font-normal text-xs whitespace-nowrap  text-legent text-left'>Started and Stopped</span>
              </div>

              <div className='w-full  flex space-x-2'>
                <div className='w-4 h-4 rounded-full' style={{ backgroundColor: '#FE6464' }}></div>
                <span className=' font-normal text-xs whitespace-nowrap  text-legent text-left'>Not yet started</span>
              </div>

            </div>)}

          </Card>
          <Card className='px-2 md:px-6  flex flex-col'>
            <h1 className='w-full text-center text-sm  mt-4 text-legent font-bold'>Financial & Physical Progress (%)</h1>
            <PieCharts piechartData={progress_count || []} loading={isLoading} colors={['#1C95D4', '#FFDD91']} />
            {isLoading ? (<></>) : (<div className='w-full  pb-2 space-y-2 flex flex-col md:flex-row justify-center items-center space-x-4'>

              <div className=' w-full flex  space-x-2 ml-4 md:ml-0 lg:mt-2'>
                <div className='w-4 h-4 rounded-full' style={{ backgroundColor: '#1C95D4' }}></div>
                <span className=' font-normal text-xs whitespace-nowrap  text-legent text-left'>Physical Progress</span>
              </div>

              <div className='w-full  flex space-x-2'>
                <div className='w-4 h-4 rounded-full' style={{ backgroundColor: '#FFDD91' }}></div>
                <span className=' font-normal text-xs whitespace-nowrap  text-legent text-left'>Financial Progress</span>
              </div>



            </div>)}


          </Card>
        </div>
      </div>

    </div>

  )
}

export default Dashboard

