import { useQueryResponse } from './Context/Core/QueryResponseProvider'
import DistrictFilter from '../DropDown'
import { QueryRequestProvider, QueryResponseProvider, DivisionDropDownMainState } from './Context'

import React, { useEffect, useMemo, useState } from 'react'
import { useQueryResponseLoading } from './Context/index'
import DropDown from '../DropDown'
import { stat } from 'fs'
import { useDispatch, useSelector } from 'react-redux'
import { divisionDropDownState, downloadReportDivisionState, dropDownReducerState } from 'src/redux/Slicer/dropdownSlicer'
import dayjs from 'dayjs'
import { VIEW_PDF } from 'src/service/apis'
import { RootState } from 'src/redux/store'



type Props = {}

const Main = (props: Props) => {

    const [selecteVal, setSelectVal] = useState<string>('')
    const { response } = useQueryResponse();
    const isLoading = useQueryResponseLoading();

    const apiResponse: any = useMemo(() => response, [response]);
    const tableData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? (apiResponse?.data ? apiResponse?.data.filter((cur: string) => cur !== '') : []) : []),
        [apiResponse, isLoading]
    );
    const dispatch = useDispatch();

    const handleState = (val: string) => {


        setSelectVal(val)

        dispatch(downloadReportDivisionState(val))

    }



    return (
        <div className='w-full '>
            <DropDown
                id='division'
                name='Select Division'
                lable='Select Division '

                jsonData={tableData || []}
                onChange={handleState}
                value={selecteVal || ''}
                isLoading={isLoading} />
        </div>
    )
}

const DivisionMain_5: React.FC<Props> = ({ }) => {

    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default DivisionMain_5
