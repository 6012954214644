/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  ERROR_CODES,
  PaginationState,
  WithChildren,
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  stringifyRequestQuery,
} from "src/helpers";
import { useQueryRequest } from "./QueryRequestProvider";
import { getDistrictDropDown } from "./_requests";
import { toast } from "react-toastify";


const QueryResponseContext = createResponseContext<any>(initialQueryResponse);
const QueryResponseProvider: FC<WithChildren> = ({
  children,
  props,
  type,
  postdata,
}) => {
  const { state, updateState } = useQueryRequest();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state));
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state]);

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery);
    }
  }, [updatedQuery]);

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `DISTRICT-DROP-DOWN-${query}`,
    async () => {
      return getDistrictDropDown(query);
    },
    {
      // cacheTime: 0,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      // refetchOnReconnect: false,
      // retry: false,
      staleTime: 10000,
    }
  );

  let responseData: any = useMemo(() => response, [response]);

  let isError = useMemo(
    () => (ERROR_CODES.includes(responseData?.code) ? true : false),
    [responseData]
  );
  let message = useMemo(
    () => (ERROR_CODES.includes(responseData?.code) ? responseData?.msg : ""),
    [responseData]
  );

  useEffect(() => {
    if (isError) {
      toast.error(message);
    }
    return () => { };
  }, [isError, message]);

  return (
    <QueryResponseContext.Provider
      value={{
        isLoading: isFetching,
        refetch,
        response,
        query,
        isError,
        message,
      }}
    >
      {children}
    </QueryResponseContext.Provider>
  );
};

const useQueryResponse = () => useContext(QueryResponseContext);

const useQueryResponseData = () => {
  const { response } = useQueryResponse();
  if (!response) {
    return [];
  }

  return response?.data || [];
};

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  };

  const { response }: any = useQueryResponse();

  if (!response || !response?.pagination) {
    return defaultPaginationState;
  }

  return response?.pagination;
};

const useQueryResponseLoading = (): boolean => {
  const { isLoading } = useQueryResponse();
  return isLoading;
};

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
  useQueryResponsePagination,
};
