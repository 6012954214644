import React from 'react';
import { useSelector } from 'react-redux';
import { dropDownReducerState } from 'src/redux/Slicer/dropdownSlicer';
import { RootState } from 'src/redux/store';
import { AVABILITY_OF_CONTRACTOR_PDF, Non_AVABILITY_OF_CONTRACTOR_PDF } from 'src/service/apis';
import DateFilter from 'src/components/ui/Common/DateFilter';
import { reportDownload } from 'src/service/services';
import { Label } from 'src/components/ui/label';
import { Button } from 'src/components/ui/button'
import { Download } from 'lucide-react'
import DivisionMain from 'src/components/ui/Common/Filter/Division/DivisionMain'


const ContractorReport = () => {
    const { monthYear, reportDivision, calendeChange, downloadReportDivision, division, villageState } = useSelector((state: RootState) => dropDownReducerState(state))

    const handleContractorReport = () => {
        // window.open(`${AVABILITY_OF_CONTRACTOR_PDF}?division=${division}&bym=${monthYear}`)
        let query = `division=${division}&bym=${monthYear}`
        let data = reportDownload(AVABILITY_OF_CONTRACTOR_PDF, query)
    }

    const handleNonContractorReport = () => {
        // window.open(`${AVABILITY_OF_CONTRACTOR_PDF}?division=${division}&bym=${monthYear}`)
        let query = `division=${division}&bym=${monthYear}`
        let data = reportDownload(Non_AVABILITY_OF_CONTRACTOR_PDF, query)
    }
    return (
        <div className='w-full'>
            <h1 className='text-left text-blue text-sm font-bold p-4'>Download  Contractor List</h1>

            <div className='w-full space-y-2 md:space-y-0 md:flex md:space-x-2 px-6 mb-4'>
                <div className='w-full'>
                    <Label className='w-full text-legent text-sm font-semibold ml-3'>Select Month/Year</Label>
                    <div className='w-full -mt-6'>
                        <DateFilter monthYear={monthYear} />
                    </div>

                </div>


                <DivisionMain />
            </div>
            <div className='w-full flex flex-col items-center space-y-2 justify-center  my-3'>
                <Button className=' bg-goBackground gap-x-4 w-72 hover:bg-goBackground rounded-full text-xs font-semibold'
                    onClick={handleContractorReport}
                    disabled={division === undefined || monthYear === undefined}>
                    <Download /> <span>Availability Download Report</span>
                </Button>

                <Button className=' bg-goBackground gap-x-4 w-72 hover:bg-goBackground rounded-full text-xs font-semibold'
                    onClick={handleNonContractorReport}
                    disabled={division === undefined || monthYear === undefined}>
                    <Download /> <span>Non-Availability Download Report</span>
                </Button>
            </div>
        </div>
    )
}

export default ContractorReport
