import { cn } from 'src/helpers/index';
import { IMenuItem } from './modules';
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import SubMenu from './SubMenu';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { dropDownReducerState, updateDashboardState } from 'src/redux/Slicer/dropdownSlicer';
import { RootState } from 'src/redux/store';

interface Rullmenu {
    name: string,
    iconName: any,
    path: string
}
interface MenuProps extends IMenuItem {
    onClick?: () => void;
}
const Menu: React.FC<MenuProps> = ({ name, iconName, path, options }) => {
    const [open, setOpen] = useState(false)
    const { pathname } = useLocation();
    const { dashboardState } = useSelector((state: RootState) => dropDownReducerState(state))

    const iconClassName = cn(
        'text-legend',
        { 'text-dashboardText': (path && pathname.includes(path)) }
    );

    const handleClick = () => {
        if (options !== undefined) {
            setOpen(!open)
        }
    }
    const dispatch = useDispatch();

    const sideBarClick = () => {
        dispatch(updateDashboardState(!dashboardState))
    }

    return (
        <div className='w-full '>

            {path !== undefined ? (
                <Link to={path} onClick={sideBarClick} className={`flex items-center space-y-5 gap-x-2 hover:text-blue  h-8  hover:border-r-4 hover:border-blue text-sm mx-1   px-2  ${path && pathname.includes(path) ? 'text-dashboardText font-bold' : 'text-legent font-normal'}`} >
                    {iconName(iconClassName)} {name}
                </Link>
            ) : (<div className='flex items-center space-x-4 font-semibold  text-gray-800 cursor-pointer h-8 hover:bg-gray-300 text-sm mx-1 rounded-md px-2' onClick={handleClick}>
                <span>{iconName(iconClassName)} </span>  <span>{name}</span> <span className=''>{open ? (<ChevronUp size={16} className={iconClassName} />) : (<ChevronDown size={16} className={iconClassName} />)}</span>
            </div>)}
            {options && <div className={'text-sm font-semibold  mr-1  hover:text-sm'}
            >
                {
                    options.map((cur, ind) => <SubMenu key={`${cur.name}`} name={cur.name}
                        iconName={cur.iconName} path={cur.path} open={open} />)
                }

            </div>}
        </div>
    )
}

export default Menu

// hover:bg-white hover:border