import React, { useEffect, useMemo, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState, VisibilityState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';

import { userReportMainState, useQueryResponsePagination, useQueryResponseLoading } from '..';
import { ScrollArea } from '../../../../components/ui/scroll-area';
import { Loader2 } from 'lucide-react';
import { DataTablePagination } from '../ReportCom/data-table-pagination';



type Props = {}
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}


const ReportTable = <TData, TValue>({ columns, data }: DataTableProps<TData, TValue>) => {

  const { state, updateState } = userReportMainState();
  const paginationState = useQueryResponsePagination()
  const isLoading = useQueryResponseLoading()

  const pagination = React.useMemo(
    () => ({
      pageIndex: state.page - 1,
      pageSize: state.items_per_page,
    }),
    [state.page, state.items_per_page]
  )



  const pageCounts = useMemo(() =>
    paginationState?.total_records ? Number(paginationState?.total_records) : 1,
    [paginationState?.total_records])


  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    pageCount: pageCounts,
    enableRowSelection: true,
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    //  onPaginationChange: ()=> updateS,
  })



  return (
    <>

      <div className="w-full relative overflow-scroll md:overflow-hidden mt-4 rounded-2xl md:p-2 mb-2 bg-white ">
        <ScrollArea className=" whitespace-nowrap md:whitespace-normal  w-max lg:w-full h-[340px] md:h-[520px] lg:h-[400px] relative rounded-md ">
          <Table className="w-max lg:w-full text-sm  text-left rtl:text-right text-legent relative">
            <TableHeader className="text-xs  uppercase bg-tableHederBackground  text-center hover:bg-tableheaderhoverBg sticky top-0 left-0 right-0 table-auto z-10">
              {table.getHeaderGroups().map((headerGroup) => <TableRow key={`${headerGroup.id}`} className='w-full'>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-left cursor-default text-legent  text-xs font-bold" scope='column'>
                      {header.isPlaceholder ? null :
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>)}

            </TableHeader>

            <TableBody
              className="w-full">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    // onClick={() =>handleClick(row.original)}
                    className='odd:bg-white odd:dark:bg-gray-900 text-xs text-legent font-normal even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 hover:bg-gray-200 cursor-default' >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className=" text-left overflow-clip" scope="row" >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    {isLoading ? <div className='flex justify-center items-center '>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
                    </div> : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

          </Table>
        </ScrollArea>
      </div>
      <div className='w-full '>
        <DataTablePagination table={table} />
      </div>


    </>
  )
}

export default ReportTable

